const coins = [
    {
        id:"1",
        name:"USDT",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
        id:"2",
        name:"BNB",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
]

export default coins
